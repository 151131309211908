<template>
  <div class="flex flex-col items-center">
    <BrandIcon v-if="!emailVerified" class="h-11 text-red w-11"/>

    <div v-if="!emailVerified" class="mt-2 text-sm text-primary text-center leading-6">
      Welcome to Spext 🙂
      <br>
      Please verify your email address to begin.
    </div>

    <Button
      v-if="!emailVerified && countdown===0 && retryCount<=2"
      @click="verifyEmail"
      classname="mt-4"
    >
      Send email again
    </Button>
    <div v-else-if="!emailVerified && countdown>0" class="mt-4 text-sm text-primary text-center leading-6">
      Didn't receive the email? You can resend in {{countdown}} seconds
    </div>
    <div v-else-if="!emailVerified" class="mt-4 text-sm text-primary text-center leading-6">
      We are having trouble reaching you. <br /> If you haven't recieved a confirmation mail, please <a @click="contactUs('I am unable to verify my email.')"
        class="cursor-pointer text-blue hover:text-blue underline hover:underline">contact us</a>.
    </div>
  </div>
</template>
<script>
import Button from '@/components/base/buttons/Button.vue'
import BrandIcon from '@/components/base/icons/Brand.vue'

export default {
  components: {
    Button,
    BrandIcon
  },
  props: ['emailVerified', 'countdown', 'retryCount', 'billing'],
  methods: {
    contactUs (message) {
      window.Intercom('showNewMessage', message)
    },
    verifyEmail () {
      this.$emit('verifyEmail')
    }
  }
}
</script>
<style scoped>
.verify-message{
font-size:18px;font-weight:bold;color:#324851;

}
.welcome-message{
  margin-bottom:15px;
  padding-top:10px;
}
</style>
